<template>
  <div class="contact">
    <div class="contact_box">
      <div class="container">
        <img class="map_img" :src="require('@/assets/img/map_bg_1.png')" alt="" />
        <div class="contact_title">
          <h3>联系我们</h3>
        </div>
        <div class="contact_text"><span>电话:</span> 15332088321</div>
        <!-- <div class="contact_text">
          <span>邮箱:</span>
          business@hnminigames.fun
        </div> -->
        <div class="contact_text">
          <span>地址:</span>
          广州市天河区荷光路137号103房C0301号
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>
<style lang="less" scoped>
.map_img {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
}
.contact_box {
  // padding-top: 50px;
  // padding-bottom: 100px;
  width: 100%;
  min-height: 717px;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .contact_title {
    text-align: center;
    h3 {
      font-size: 42px;
      color: rgb(152, 152, 152);
    }
  }
  .contact_text {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
    color: rgb(152, 152, 152);
    span {
      font-weight: bold;
    }
  }
}
</style>
